<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Carta</h5>
			</div>
			<div class="action-btn-wrap">
				<v-btn color="secondary" class="mr-1" @click.prevent="showImportDialog = true"
					>Importar</v-btn
				>
				<v-btn color="accent" @click.prevent="clickCreate()">Nuevo</v-btn>
			</div>
		</div>
		<v-row dense>
			<v-col cols="12" xl="3" lg="4" md="4" sm="12">
				<el-input
					placeholder="Buscar..."
					v-model="search.value"
					prefix-icon="el-icon-search"
					@input="getRecords"
				>
				</el-input>
			</v-col>
		</v-row>
		<div class="shop-content-wrap">
			<v-row dense>
				<v-col
					v-for="(row, index) in records"
					cols="12"
					sm="6"
					md="4"
					lg="3"
					xl="2"
					class="d-flex align-items-stretch"
					:key="index"
					:index="customIndex(index)"
				>
					<v-card hover dark color="secondary">
						<v-img
							:src="row.image"
							class="white--text align-center"
							gradient="to bottom, rgba(0,0,0,.6), rgba(0,0,0,.9)"
							height="200px"
						>
							<p class="text-center font-weight-medium mt-3 ma-1 white--text">
								{{ row.name }}
							</p>
						</v-img>

						<p class="text-center caption mt-3">
							{{ row.description ? row.description : 'NO TIENE DESCRIPCIÓN' }}
						</p>

						<v-card-actions>
							<v-btn @click="clickDelete(row.id)" color="accent" class="px-0" icon>
								<v-icon>mdi-delete</v-icon>
							</v-btn>
							<v-btn
								@click="openDialogImage(row.id, row.image)"
								color="accent"
								class="px-0"
								icon
							>
								<v-icon>mdi-image-area</v-icon>
							</v-btn>
							<template v-if="row.stock_control">
								<v-spacer></v-spacer>
								<h6 class="mt-2">
									<strong>Stock: {{ row.stock }}</strong>
								</h6>
							</template>
							<v-spacer></v-spacer>
							<h6 class="mt-2">
								<strong>{{ row.sale_price }}</strong>
							</h6>
						</v-card-actions>
						<v-card-actions class="mb-2">
							<el-button
								type="primary"
								class="btn-block"
								round
								@click="clickCreate(row.id)"
							>
								Modificar
							</el-button>
						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
			<v-pagination
				@next="getRecords"
				@previous="getRecords"
				@input="getRecords"
				:length="pagination.last_page"
				:total-visible="7"
				v-model="pagination.current_page"
				circle
				prev-icon="mdi-chevron-left"
				next-icon="mdi-chevron-right"
			></v-pagination>
		</div>

		<item-form :showDialog.sync="showDialog" :recordId="recordId"></item-form>

		<items-import :showImportDialog.sync="showImportDialog"></items-import>

		<image-form
			:showDialogImage.sync="showDialogImage"
			:recordId="recordId"
			:imageUrl="imageUrl"
		></image-form>
	</div>
</template>

<script>
import { deletable } from 'Mixins/deletable';
import queryString from 'query-string';
import ItemForm from './Form.vue';
import ImageForm from './Component/Image.vue';
import ItemsImport from './Component/Import.vue';

export default {
	data() {
		return {
			showDialog: false,
			showDialogImage: false,
			showImportDialog: false,
			resource: 'items',
			recordId: null,
			records: [],
			pagination: {},
			search: {
				value: null,
			},
			imageUrl: null,
		};
	},
	mixins: [deletable],
	components: {
		ItemsImport,
		ItemForm,
		ImageForm,
	},
	created() {
		this.$eventHub.$on('reloadData', (resource) => {
			if (this.resource == resource) {
				this.getRecords();
			}
		});
	},
	async mounted() {
		await this.getRecords();
	},
	methods: {
		customIndex(index) {
			return this.pagination.per_page * (this.pagination.current_page - 1) + index + 1;
		},
		getRecords() {
			return this.$http
				.get(`/${this.resource}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
					this.pagination = response.data.meta;
					this.pagination.per_page = parseInt(response.data.meta.per_page);
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				page: this.pagination.current_page,
				limit: this.limit,
				...this.search,
			});
		},
		clickCreate(recordId = null) {
			this.recordId = recordId;
			this.showDialog = true;
		},
		openDialogImage(recordId, imageUrl) {
			this.recordId = recordId;
			this.imageUrl = imageUrl;
			this.showDialogImage = true;
		},
		clickDelete(id) {
			this.destroy(`/${this.resource}/${id}`).then(() =>
				this.$eventHub.$emit('reloadData', this.resource)
			);
		},
	},
};
</script>
