<template>
	<el-dialog
		title="Imagen del producto"
		:visible="showDialogImage"
		@close="close()"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		center
	>
		<form autocomplete="off" @submit.prevent="uploadImage" enctype="multipart/form-data">
			<v-row>
				<v-col cols="12" xl="10" offset-xl="1">
					<v-img
						:src="UrlUploadImage ? UrlUploadImage : imageUrl"
						aspect-ratio="1"
						class="grey lighten-2 pr-2 mx-auto d-block"
						max-width="500"
						max-height="350"
					>
					</v-img>
					<el-upload
						:data="{ item_id: recordId }"
						ref="upload"
						:headers="headers"
						:action="`${appUrlBackEnd}/api/items/upload`"
						:show-file-list="false"
						:auto-upload="true"
						:multiple="false"
						:limit="1"
						:before-upload="beforeUpload"
						:on-success="successUpload"
						:on-error="errorUpload"
						class="text-center"
					>
						<el-button slot="trigger" class="mt-4" type="accent"
							>Seleccione una imagen</el-button
						>
					</el-upload>
				</v-col>
			</v-row>
		</form>
	</el-dialog>
</template>

<script>
import AppConfig from 'Constants/AppConfig';

export default {
	props: ['showDialogImage', 'recordId', 'imageUrl'],
	data() {
		return {
			appUrlBackEnd: AppConfig.appUrlBackEnd,
			resource: 'items',
			headers: {
				Authorization: null,
			},
			loading: false,
			form: {
				image: null,
			},
			UrlUploadImage: null,
		};
	},
	methods: {
		// preview(file) {
		//     this.UrlUploadImage = URL.createObjectURL(file.raw);
		// },
		async beforeUpload(file) {
			const accessToken = document.cookie.replace(
				/(?:(?:^|.*;\s*)access_token=\s*([^;]*).*$)|^.*$/,
				'$1'
			);

			this.headers.Authorization = `Bearer ${accessToken}`;

			const isIMG =
				file.type === 'image/jpeg' ||
				file.type === 'image/png' ||
				file.type === 'image/jpg';
			const isLt2M = file.size / 1024 / 1024 < 2;

			if (!isIMG) this.$message.error('La imagen no es valida!');
			if (!isLt2M) this.$message.error('La imagen excede los 2MB!');

			return isIMG && isLt2M;
		},
		async uploadImage() {
			this.loading = true;
			await this.$refs.upload.submit();
			this.loading = false;
		},
		successUpload(response) {
			if (response.success) {
				this.UrlUploadImage = response.image_url;
				this.$message.success(response.message);
				this.$eventHub.$emit('reloadData', this.resource);
				this.$refs.upload.clearFiles();
				this.close();
			} else {
				this.$message({ message: response.message, type: 'error' });
			}
		},
		errorUpload(response) {
			// this.$message.error(response.data.message)
			console.log(response);
		},
		close() {
			this.$emit('update:showDialogImage', false);
		},
	},
};
</script>
